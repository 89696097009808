* {
	font-family: 'Inter', sans-serif;
}

body::before {
	background-image: url(/images/header-gradient-l.svg);
	background-repeat: no-repeat;
	background-position: center top, center bottom;
	background-size: 1400px;
	content: "";
	position: absolute;
	filter: blur(76px);
	inset: 0px;
	z-index: -100;
}

.navbar {
	min-height: 4.25rem !important;
}

.navbar-brand {
	img {
		transform: scale(4.5);
    position: relative;
    top: 4px;
    left: 112px;
	}
}

.navbar-link.is-active, 
.navbar-link:focus, 
.navbar-link:focus-within, 
.navbar-link:hover, 
a.navbar-item.is-active, 
a.navbar-item:focus, 
a.navbar-item:focus-within, 
a.navbar-item:hover {
	background-color: unset !important;
	color: magenta !important;
}

.navbar-burger {
	z-index: 99999;
}

.navbar-burger:hover {
	background-color: unset !important;
}

.content footer figure blockquote {
	background-color: unset;
	border-left: unset;
	font-weight: 700;
	padding: unset;
}

@media screen and (min-width: 1024px) {
	.content {
		width: 600px;
	}
}

@media screen and (max-width: 798px) {

	.content {
		width: 390px;
	}

	main {
		padding: 0 1rem;
	} 

	.navbar-menu {
		transition: all .75s ease;
	}

	.navbar-menu.is-active {
		background-color: rgb(245, 243, 238);
		background-image: url(https://res.cloudinary.com/leesheppard/image/upload/c_thumb,w_500,g_face/v1698233841/Ruby%20Kraken.png);
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: contain;
		display: block;
		font-size: xx-large;
		min-height: 100vh;
		padding: 10rem 1rem;
		position: absolute;
		top: -88px;
		right: 0%;
		width: 100%;
		text-align: right;
		z-index: 9999;
	}

	.navbar-brand img {
		transform: scale(3.5);
		position: relative;
		top: 4px;
		left: 66px;
	}

	.peace img {
		transform: scale(1);
    position: absolute;
    top: -50px;
		left: 190px !important;
	}
}

.grid-container {
	display: grid;
	gap: 0 0;
	grid-template-columns: 90px 90px 90px 90px 90px;
}

.image {
	height: 400px;
	width: 90px;
	object-fit: cover;
	object-position: 0% 0;

	&.one {
		margin-top: 15px;
		object-position: 20% 0;
		transform: rotate(-3deg);
	}

	&.two {
		object-position: 25% 0;
	}

	&.three {
		margin-top: 28px;
		object-position: 55% 0;
		transform: rotate(3deg);
	}

	&.four {
		margin-top: 8px;
		object-position: 80% 0;
	}

	&.five {
		margin-top: 15px;
		object-position: 100% 0;
		transform: rotate(4deg);
	}
}

.signature {
	display: flex;
	flex-direction: row;
}

.glass {
	background: rgba(255, 255, 255, 0.2);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border: 1px solid rgba(255, 255, 255, 0.3);
}

.quote {
	// @extend .glass

	background:linear-gradient(90deg,rgba(255,233,244,0.4) 0%,rgba(234,243,255,0.7) 100%);
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.quote figcaption {
	font-style: italic;
}

.quote figcaption,
.quote blockquote {
  margin: .5em;
}

.container p {
	font-size: 20px;
	line-height: 140%;
	font-weight: 400;
	margin: 16px 0px;
}

footer .links {
 a {
	color: #ccc;
 }
}

.peace img {
	transform: scale(1);
	position: absolute;
	top: -50px;
	left: 258px;
}
